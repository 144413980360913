const body = document.querySelector("body");

if (body.classList.contains("page-template-gallery")) {
  const buttons = document.querySelectorAll(".gallery__button");
  buttons.forEach((btn) => {
    btn.addEventListener("click", () => {
      const allRows = document.querySelectorAll(".gallery__row");
      const currentRows = document.querySelectorAll(`.gallery__row--${btn.id}`);

      buttons.forEach((btn) => {
        btn.classList.remove("gallery__button--active");
      });
      btn.classList.add("gallery__button--active");

      allRows.forEach((row) => {
        row.style.display = "none";
        const children = row.childNodes;

        children.forEach((child) => {
          if (child.nodeName === "A") {
            delete child.dataset.fslightbox;
            refreshFsLightbox();
          }
        });
      });

      currentRows.forEach((currentRow) => {
        currentRow.style.display = "flex";
        const currentChildren = currentRow.childNodes;

        currentChildren.forEach((child) => {
          if (child.nodeName === "A") {
            child.setAttribute("data-fslightbox", "");
            refreshFsLightbox();
          }
        });
      });

      if (btn.id === "all") {
        allRows.forEach((row) => {
          row.style.display = "flex";
          const children = row.childNodes;

          children.forEach((child) => {
            if (child.nodeName === "A") {
              child.setAttribute("data-fslightbox", "");
              refreshFsLightbox();
            }
          });
        });
      }
    });
  });
}
