const arrowLeftFirst = document.querySelector(
  ".carrerStagesFirst__arrow--left"
);
const arrowRightFirst = document.querySelector(
  ".carrerStagesFirst__arrow--right"
);

const arrowLeftSecond = document.querySelector(
  ".carrerStagesSecond__arrow--left"
);
const arrowRightSecond = document.querySelector(
  ".carrerStagesSecond__arrow--right"
);

const itemsFirst = document.querySelectorAll(".carrerStages__item--production");
const itemsSecond = document.querySelectorAll(
  ".carrerStages__item--administration"
);

let indexFirst = 0;
let indexSecond = 0;

if (arrowRightFirst) {
  arrowRightFirst.addEventListener("click", () => {
    if (indexFirst < 2) {
      indexFirst++;
      resetDotsFirst();
    }
    if (indexFirst === 2) {
      itemsFirst[indexFirst].classList.remove("carrerStages__item--disabled");
    }
  });
}

if (arrowLeftFirst) {
  arrowLeftFirst.addEventListener("click", () => {
    if (indexFirst > 0) {
      indexFirst--;
      resetDotsFirst();
    }
    itemsFirst[2].classList.add("carrerStages__item--disabled");
  });
}

if (arrowRightSecond) {
  arrowRightSecond.addEventListener("click", () => {
    if (indexSecond < 3) {
      indexSecond++;
      resetDotsSecond();
    }
    if (indexSecond === 3) {
      itemsSecond[indexSecond].classList.remove("carrerStages__item--disabled");
    }
  });
}

if (arrowLeftSecond) {
  arrowLeftSecond.addEventListener("click", () => {
    if (indexSecond > 0) {
      indexSecond--;
      resetDotsSecond();
    }
    itemsSecond[3].classList.add("carrerStages__item--disabled");
  });
}

const resetDotsFirst = () => {
  itemsFirst.forEach((item) => {
    item.childNodes[1].classList.remove("carrerStages__dot--active");
  });
  itemsFirst[indexFirst].childNodes[1].classList.add(
    "carrerStages__dot--active"
  );
};

const resetDotsSecond = () => {
  itemsSecond.forEach((item) => {
    item.childNodes[1].classList.remove("carrerStages__dot--active");
  });
  itemsSecond[indexSecond].childNodes[1].classList.add(
    "carrerStages__dot--active"
  );
};
